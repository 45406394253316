<template>
    <v-row align="center">
        <v-col class="text-center" v-if="!!chips.length" >
            <v-avatar
            v-for="(chip, i) in chips" :key="'chip-'+i"
            :color="chip"
            class="ma-1"
            size="40"
            />
        </v-col>
        <v-col v-else>
            <div class="text-center">
                <v-avatar color=""
                class="ma-1"
                size="40"
                />
            </div>
        </v-col>
        <v-divider vertical/>
        <v-col cols="auto">
            <v-btn color="red" icon
            :dark="!!chips.length"
            :disabled="!chips.length"
            @click="backspace">
                <v-icon>mdi-backspace</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        chips: Array,
    },
    methods: {
        backspace() {
            this.$emit('backspace');
        },
    }
}
</script>