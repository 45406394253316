<template>
    <v-container>
        <color-chips :chips="chips" @backspace="removeChip"/>

        <v-divider class="my-3"/>

        <color-select @addColor="addChip" />

        <v-divider class="mt-3 mb-10"/>

        <sn-slider
        v-model="sliderValue"
        :alert="snImportant"/>

        <v-divider class="my-3"/>

        <div class="text-center">
            <span class="text-h4">Cut the</span>
            <br>
            <span class="text-h3">{{ wireToCut }}</span>
            <br>
            <span class="text-h4">wire</span>
        </div>

        <v-divider class="my-3"/>

        <v-btn block color="secondary"
        large
        @click="resetModule">
            Reset
        </v-btn>

        <v-snackbar
        v-model="snackbar"
        color="primary">
            Max. 6 wires!
            <template #action>
                <v-btn text @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import colorChips from '../components/modules/simple-wires/color-chips.vue'
import ColorSelect from '../components/modules/simple-wires/color-select.vue';
import SnSlider from '../components/settings/sn-slider.vue';

export default {
    components: { colorChips, ColorSelect, SnSlider },
    data: () => ({
        chips: [],
        wireToCut: '???',
        sliderValue: 0,
        snImportant: false,
        snackbar: false,
    }),
    methods: {
        removeChip() {
            if (this.chips.length) {
                this.chips.pop();
            }
        },
        addChip(color) {
            if (this.chips.length < 6)
                this.chips.push(color);
            else
                this.snackbar = true;
        },
        resetModule() {
            this.chips = [];
            this.snImportant = false;
        },
        calculateResult() {
            const wires = this.chips;
            const nWires = wires.length;
            const sn = this.sliderValue;

            const nRed = countOccurrences(wires, 'red');
            const nBlue = countOccurrences(wires, 'blue');
            const nYellow = countOccurrences(wires, 'yellow');
            const nBlack = countOccurrences(wires, 'Black');
            const nWhite = countOccurrences(wires, 'grey lighten-2');

            let wireToCut = '';
            // console.log(wires);

            if (nWires == 3) {
                this.snImportant = false;
                if (!wires.includes('red'))
                    wireToCut = 'second';
                else if (wires[2] == 'grey lighten-2')
                    wireToCut = 'last';
                else if (nBlue > 1)
                    wireToCut = 'last blue';
                else
                    wireToCut = 'last';
            }
            else if (nWires == 4) {
                if (nRed > 1 && (sn % 2))
                    wireToCut = 'last red';
                else if (wires[3] == 'yellow' && nRed == 0)
                    wireToCut = 'first';
                else if (nBlue == 1)
                    wireToCut = 'first';
                else if (nYellow > 1)
                    wireToCut = 'last';
                else
                    wireToCut = 'second';
            }
            else if (nWires == 5) {
                if (wires[4] == 'black' && (sn % 2))
                    wireToCut = 'fourth';
                else if (nRed == 1 && nYellow > 1)
                    wireToCut = 'first';
                else if (nBlack == 0)
                    wireToCut = 'second';
                else
                    wireToCut = 'first';
            }
            else if (nWires == 6) {
                if (nYellow == 0 && (sn % 2))
                    wireToCut = 'third';
                else if (nYellow == 1 && nWhite > 1)
                    wireToCut = 'fourth';
                else if (nRed == 0)
                    wireToCut = 'last'
                else
                    wireToCut = 'fourth'
            }
            else
                wireToCut = '???';

            if ((nWires == 4 &&  nRed > 1) || (nWires == 5 &&  wires[4] == 'black') || (nWires == 6 && nYellow == 0 ))
                this.snImportant = true;
            else
                this.snImportant = false;

            this.wireToCut = wireToCut;
        }
    },
    updated() {
        this.calculateResult();
    },
}

function countOccurrences(array, key) {
    let result = 0;
    array.forEach(el => {
        if (el == key) result++;
    });
    return result;
}
</script>

<style>

</style>