<template>
    <div>
        <v-row justify="center">
            <v-col v-for="color in colors" :key="'btn-'+color.value"
            cols="6" sm="4" md="3" lg="2">
                <v-btn 
                block
                x-large
                :color="color.value"
                :dark="color.dark"
                @click="addColor(color)">
                    {{ color.name }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        colors: [
            { value: 'black', name: 'Black', dark: true },
            { value: 'blue', name: 'Blue', dark: true },
            { value: 'red', name: 'Red', dark: true },
            { value: 'grey lighten-2', name: 'White', dark: false },
            { value: 'yellow', name: 'Yellow', dark: false },
        ]
    }),
    methods: {
        addColor(color) {
            this.$emit('addColor', color.value);
        }
    }
}
</script>

<style>

</style>